import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { arrow } from "../../icons/svg.js";
import { RichText } from "../../internal-components/richText/RichText.js";
import { getLinkProps } from "../../client-utils/getLinkProps.js";

interface Props {
  title?: string;
  text?: string;
  link?: Link;
  backgroundImage?: Image;
  rightColumnText?: string;
}

const CtaBecomeMember: React.FC<Props> = ({
  title,
  text,
  link,
  backgroundImage,
  rightColumnText,
}) => {
  return (
    <div className="cta-become-member hs vs-xl mw">
      <div
        className="container"
        style={
          backgroundImage
            ? {
                backgroundImage: `url(${transformImageUrl(backgroundImage, {
                  height: 1200,
                })})`,
              }
            : undefined
        }
      >
        <div className="section section-left">
          {title && <h2 className="title">{title}</h2>}
          {text && <RichText className="text b2" html={text} />}
          {link && (
            <a className="button tertiary web-button" {...getLinkProps(link)}>
              {link.title} {arrow}
            </a>
          )}
        </div>
        {rightColumnText && (
          <div
            className="section section-right b2"
            dangerouslySetInnerHTML={{ __html: rightColumnText }}
          />
        )}
        <div className="mobile-button">
          {link && (
            <a className="button tertiary" {...getLinkProps(link)}>
              {link.title} {arrow}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CtaBecomeMember;
